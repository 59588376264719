import {Injectable} from '@angular/core';
import {BaseTableService} from "../../common/base-table/base-table.service";
import {Observable} from "rxjs";
import {IPage} from "../../common/page.interface";
import {HttpParams} from "@angular/common/http";
import {ApiGatewayService} from "../../services/api-gateway.service";
import {IResourceDownload} from "../../common/resource-download.interface";

@Injectable({
    providedIn: 'root'
})
export class ApplicationsService extends BaseTableService {
    override downloadResource(resourceName: string): Observable<IResourceDownload> {
        throw new Error('Method not implemented.');
    }

    constructor(private api: ApiGatewayService) {
        super();
    }

    name = 'Aplicaciones';
    _applyLastFilter: boolean;
    _lastFilter: any;

    getAll(): Observable<any> {
        return this.api.get<{value: string, name: string}[]>('/management/applications/all');
    }

    getAllPageable(pageNumber: number, pageSize: number, filters?: any): Observable<IPage<any>> {
        let params = new HttpParams()
            .append('number', pageNumber)
            .append('size', pageSize);

        if (filters) {
            for (let filterName of Object.keys(filters)) {
                if (filters[filterName] && filterName !== 'paymentDateFrom' && filterName !== 'paymentDateTo') {
                    params = params.append(filterName, filters[filterName]);
                }
            }

            if (filters.paymentDateFrom && filters.paymentDateTo) {
                params = params
                    .append('paymentDateFrom', filters.paymentDateFrom.toString())
                    .append('paymentDateTo', filters.paymentDateTo.toString());
            }
        }

        return this.api.get<IPage<any>>('/management/applications', params);
    }

    getAllPageableById(pageNumber: number, pageSize: number, id: string, filters?: any): Observable<IPage<any>> {
        return new Observable<IPage<any>>();
    }

    getDetail(id: string): Observable<any> {
        return new Observable<any>();
    }

    getPlans() {
        return this.api.get('/management/plan/all');
    }

    register(request: any) {
        return this.api.post('/management/applications', request);
    }

    getReport(filters: any): Observable<any> {
        return new Observable();
    }

    getAllProviders() {
        return this.api.get<{value: string, name: string}[]>('/management/providers/all');
    }
}
